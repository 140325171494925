function lazyLoadPromise () {
	new Promise((resolve, reject) => {
		const images = document.querySelectorAll('img[data-src], source[data-srcset]')
	
		images.forEach(img => {
			if (img.getAttribute('data-src')) img.setAttribute('src', img.getAttribute('data-src')) 
			if (img.getAttribute('data-srcset')) img.setAttribute('srcset', img.getAttribute('data-srcset'))
	
			img.removeAttribute('data-src')
			img.removeAttribute('data-srcset')
		})
		
		resolve()
	})
}

class VideoResponser {
    constructor(el) {
        const $video = el
        this.options = { 
            el, 
            breakpoints: { default: { src: $video.getAttribute('data-src') } } 
        };

        $video.querySelectorAll('[data-src]').forEach(element => {
            this.options.breakpoints[element.getAttribute('data-mw')] = { src: element.getAttribute('data-src') }
        })
        $video.innerHTML = '';
        $video.removeAttribute('data-src')
        

        this.responseVideo(this.options)
        this.resizer()
    }

    
    resizer() {
        window.addEventListener('resize', () => this.responseVideo(this.options))
    }
    
    responseVideo(options) {
        const {el, breakpoints} = options
        let $video = el
        const widthNow = $video.getAttribute('data-width-now') || null
        let maxBreakpoint = Math.max.apply(Math, Object.keys(breakpoints).filter(key => key <= document.body.clientWidth).map(Number))
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/) && breakpoints.iphone) maxBreakpoint = 'iphone'
        const nowBreakpoint = maxBreakpoint || 'default'

        if (widthNow && widthNow == nowBreakpoint) return

        $video.setAttribute('data-width-now', nowBreakpoint)
        $video.src = breakpoints[nowBreakpoint].src
    }
}

function initLazyLoadVideo() {
    const videos = document.querySelectorAll('.lazy-video')

    videos && videos.length && videos.forEach((video) => {
        new VideoResponser(video)

        // video.load()

        // video.addEventListener('loadeddata', () => {
        //     video.classList.remove('lazy-video')
        //     video.playsInline = true
        //     video.autoplay = true
        //     const promise = video.play()

        //     if (promise !== undefined) {
        //         promise.catch(error => {
                    
        //         }).then(() => {
                    
        //         })
        //     }
           
        // }, false)   
    })
}

initLazyLoadVideo();

AOS.init({
	duration: 2000,
	once: true,
});

$( document ).ready( function() {
	lazyLoadPromise()

	$('input[type="search"]').on('input',function () {
		 if ($(this).val().length > 0) {
			 $(this).addClass('active')
			 $('.search-dropdown').fadeIn();
			 $('.js-search-clear').fadeIn();
		}
		 else {
			 $(this).removeClass('active');
			 $('.search-dropdown').fadeOut()
			 $('.js-search-clear').fadeOut();
		 }
	});

	$('.js-search-clear').click(function () {
		$('.search input').val('').focus();
		$('.search-dropdown').fadeOut()
		$('.js-search-clear').fadeOut();
	});

	$('.js-menu').click(function () {
		$('.menu').addClass('active');
		$('main, footer, header .container').addClass('faded');
	});

	$('.js-menu-close').click(function () {
		$('.menu').removeClass('active');
		$('main, footer, header .container').removeClass('faded');
	});

	// $('.support-text span').dotdotdot();


	$( '.js-care-slider' ).slick({
		infinite: false,
		arrows: true,
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 2,
		swipeToSlide: true,
		cssEase: 'ease',
		touchThreshold: 20,
		speed: 600,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	});

	$( '.js-advice-slider' ).slick({
		infinite: false,
		arrows: true,
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 3,
		swipeToSlide: true,
		cssEase: 'ease',
		touchThreshold: 20,
		speed: 600,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	});

	$( '.js-footer-slider' ).slick({
		infinite: false,
		arrows: true,
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 3,
		swipeToSlide: true,
		cssEase: 'ease',
		touchThreshold: 20,
		speed: 600,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	});

	$( document ).on( 'click', '.js-leisure-toggle', function( e ) {
		e.preventDefault();
		const $this = $( this );
		const $box = $this.closest( '.leisure-box' );
		const $content = $box.find( '.leisure-box__content' );
		const textShow = $this.attr( 'data-text-show' );
		const textHide = $this.attr( 'data-text-hide' );
		if ( !$this.hasClass( 'active' ) ) {
			$this.addClass( 'active' );
			$content.slideDown( 300 );
			$this.find( 'span' ).text( textHide );
		} else {
			$this.removeClass( 'active' );
			$content.slideUp( 300 );
			$this.find( 'span' ).text( textShow );
		}
	});

	function mobileOnlySlider(){
		$('.article-aside__wrapper').slick({
			infinite: false,
			arrows: true,
			dots: false,
			slidesToScroll: 1,
			slidesToShow: 1,
			swipeToSlide: true,
			cssEase: 'ease',
			touchThreshold: 20,
			speed: 600,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
						arrows: false
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
				}
			]
		});
	}

	function checkWindowSize() {
		if (window.innerWidth < 992) {
			if (!$('.article-aside__wrapper').hasClass('slick-initialized')) {
				mobileOnlySlider();
			}
		} else {
			if ($('.article-aside__wrapper').hasClass('slick-initialized')) {
				$('.article-aside__wrapper').slick('unslick');
			}
		}
	}

	$(window).resize(function(e){
		checkWindowSize();
	});

	if(window.innerWidth < 992) {
		if(!$('.article-aside__wrapper').hasClass('slick-initialized')){
			mobileOnlySlider();
		}
	}

	const observer = new MutationObserver(checkWindowSize);

    observer.observe(document.body, { attributes: true, subtree: true, attributeFilter: ['class', 'style'] });

    $(window).on('unload', function() {
        observer.disconnect();
    });

	$(document).on('click', 'a[href*="#"]', function (e) {
		$('.menu').removeClass('active');
		$('main, footer, header .container').removeClass('faded');

		let blockHref = $(this).attr('href').split('#');
		let blockID = blockHref[1];
		const element = document.getElementById(blockID);
		const elementRect = element.getBoundingClientRect().top;


		if (elementRect) {
			const offset = $(element).offset().top - $('.header').height() - 25;
			
			$('html, body').animate({
				scrollTop: offset
			}, 800);
		}
	})

	$('.show-items').on('click', function() {
		$('.question-list .question-box').fadeIn();
		$('.show-items').parent('.question__btn').addClass('hide')
	})
	
});


document.addEventListener('DOMContentLoaded', () => {
	if (window.location.hash) {

		setTimeout(() => {
			const blockID = (window.location.hash).substr(1); 
			const element = document.getElementById(blockID);

			window.scrollTo(0,0);

			const elementRect = element.getBoundingClientRect().top;

			if (elementRect) {
				const offset = $(element).offset().top - $('.header').height() - 25;
		
				$('html, body').animate({
					scrollTop: offset
				}, 800); 
			}
		}, 10);
	}

	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	
})

window.addEventListener('load', () => {
	document.querySelectorAll('iframe[data-src]').forEach((el) => {
		el.setAttribute('src', el.getAttribute('data-src'));
	});
});


function onReady(event) {
	event.target.playVideo();
};

function onYouTubeIframeAPIReady() {
	var videoContainers = document.querySelectorAll('.video-container');

	videoContainers.forEach(function(container, index) {
		var playButton = container.querySelector('.play-button');
		var poster = container.querySelector('.support-poster');
		var videoId = container.getAttribute('data-video-id');

		if(!container.querySelector('iframe')) {

			playButton.addEventListener('click', function(e) {
				e.preventDefault();

				var playerContainer = document.createElement('div');
				playerContainer.setAttribute('id', 'ytplayer-' + index);

				container.prepend(playerContainer);

				new YT.Player(playerContainer, {
					videoId: videoId,
					autoplay: true,
					events: {
						'onReady': onReady
					}
				});
				
				poster.style.display = 'none';
				playButton.style.display = 'none';

			});
			
		}
	});
}